import { useState } from 'react';
import styled from 'styled-components';
import Sidebar from './Sidebar/Sidebar';
import SiteFrame from './Frame/SiteFrame';
import { useEffect } from 'react';
import { ImSpinner8 } from 'react-icons/im';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetcher } from 'utils/Fetch';
import ndjsonStream from 'can-ndjson-stream';

const Component = styled.div`
	height: 100vh;
	width: 100vw;
	display: grid;
	grid-template-columns: 30rem minmax(960px, 1fr);
`;

const Loading = styled.div`
	position: fixed;
	inset: 0;
	display: grid;
	place-items: center;
	backdrop-filter: blur(0.5rem);
	background: #0006;
	z-index: 999;
	pointer-events: none;
`;

const Spinner = styled(ImSpinner8)`
	width: 5vw;
	height: 5vw;
	animation: spin 1s infinite;
	color: white;
`;

const NewReport = () => {
	// State
	const [params, setParams] = useState({ engine: 'axe-core', scan: 'single-page', url: '' });
	const [isValidUrl, setIsValidUrl] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedElement, setSelectedElement] = useState(null);
	const [html, setHtml] = useState(null);
	const [report, setReport] = useState(null);
	const [error, setError] = useState(null);

	// Hooks
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	// Effects
	useEffect(() => {
		const newUrl = searchParams.get('new_report_url');
		if (newUrl) {
			setParams(prev => ({ ...prev, url: newUrl }));
			setSearchParams({});
		}
	}, [searchParams, setSearchParams]);

	useEffect(() => {
		if (params.url) {
			try {
				let correctedUrl = '';
				const { host, pathname } = new URL(params.url);
				if (host === 'dashboard.appfoliowebsites.com') {
					const correctedPath = pathname.replace('/home/site/', '/site/').replace('/preview/', '/site/');
					correctedUrl = 'https://' + host + correctedPath;
				} else {
					correctedUrl = 'https://' + host + pathname;
				}
				setParams(prev => ({ ...prev, url: correctedUrl }));
				setIsValidUrl(true);
			} catch (error) {
				setParams(prev => ({ ...prev, url: params.url }));
				setIsValidUrl(false);
			}
		}
	}, [params.url]);

	useEffect(() => {
		if (html && report) {
			setTimeout(() => setIsLoading(false), 1000);
			navigate(`/report?url=${params.url}`);
		}
	}, [html, report, params.url, navigate]);

	useEffect(() => {
		if (isLoading) {
			setSelectedElement(null);
			setHtml(null);
			setReport(null);
		}
	}, [isLoading]);

	// Fetch
	const fetchHtml = async () => {
		const data = await fetcher('/api/v1/html', { method: 'POST', body: JSON.stringify({ params }) });
		return data;
	};

	const fetchReport = async () => {
		const response = await fetch('/api/v1/reports/new', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ params }) });
		const stream = ndjsonStream(response.body).getReader();
		const { value: data } = await stream.read();
		return data;
	};

	// Handlers
	const handleSubmit = async e => {
		e.preventDefault();
		setIsLoading(true);
		try {
			const [htmlData, reportData] = await Promise.all([fetchHtml(), fetchReport()]);
			if (reportData.error) throw new Error(reportData.message);
			setHtml(htmlData);
			setReport(reportData);
		} catch (error) {
			setError(error.message);
		}
	};

	const handleClear = () => {
		setIsLoading(false);
		setParams({ engine: 'axe-core', scan: 'single-page', url: '' });
		setSelectedElement(null);
		setHtml(null);
		setReport(null);
		setError(null);
		navigate('/report');
	};

	return (
		<Component>
			<Helmet>
				<title>New Page Report | Axe Accessibility Tool</title>
			</Helmet>
			{isLoading && !error && (
				<Loading>
					<Spinner />
				</Loading>
			)}
			<Sidebar params={params} setParams={setParams} handleSubmit={handleSubmit} handleClear={handleClear} error={error} report={report} selectedElement={selectedElement} setSelectedElement={setSelectedElement} isValidUrl={isValidUrl} />
			<SiteFrame html={html} selectedElement={selectedElement} error={error} />
		</Component>
	);
};

export default NewReport;
